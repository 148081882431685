// Imports

// Colours and Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+Toto:wght@400;500;600;700&display=swap");

$inter: "Inter", sans-serif;
$noto: "Noto Serif Toto", serif;

$border: 1px solid red;

// Overall
html {
  margin: 0;
  padding: 0;
  body {
    margin: 0;
  }
  overflow-x: hidden;
}

.App-Container {
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;

  @media only screen and (max-width: 700px) {
    padding: 3rem 1rem;
  }

  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
  // height: 100vh;

  background-image: radial-gradient(
      at 44% 0%,
      rgba(255, 184, 122, 0.4) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, rgba(31, 221, 255, 0.1) 0px, transparent 50%),
    radial-gradient(at 80% 50%, rgba(255, 133, 173, 0.1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, rgba(255, 181, 138, 0) 0px, transparent 50%),
    radial-gradient(at 80% 100%, rgba(107, 102, 255, 0) 0px, transparent 50%),
    radial-gradient(at 0% 0%, rgba(255, 133, 167, 0.25) 0px, transparent 50%);
}

// Header
.Header {
  .Header-Logo {
    height: 100px;
    align-items: center;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 200px;
  }
}

// Main
.Main-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: clamp(400px, 78vh, 90vh);
}

.Main {
  // height: clamp(400px, 70vh, 90vh);
  align-items: center;

  width: 60%;

  p {
    font-family: $inter;

    a {
      text-decoration: none;
      color: black;
    }
  }
  h1 {
    font-family: $inter;
  }

  @media only screen and (max-width: 700px) {
    width: 80%;
  }
}

// Blobs
.Blobs-Showcase {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-top: 25px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  .Blobs {
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 0 25px;
    width: 220px;
    border-radius: 25px;
    font-family: $inter;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      margin-top: 25px;
    }

    svg {
      font-size: 3rem;
    }
    h4,
    h3 {
      margin: 0;
      padding-top: 15px;
    }
  }

  .Blobs:hover {
    // z-index: 999;
  }

  #blob0 {
    position: absolute;
    top: 25%;
    right: 20%;

    @media only screen and (max-width: 1000px) {
      position: relative;
      display: flex;

      top: 0;
      right: 0;
    }
  }
  #blob1 {
    position: absolute;
    top: 45%;
    right: 5%;

    @media only screen and (max-width: 1000px) {
      position: relative;
      display: flex;

      top: 0;
      right: 0;
    }
  }
  #blob2 {
    position: absolute;
    top: 65%;
    right: 20%;

    @media only screen and (max-width: 1000px) {
      position: relative;
      display: flex;

      top: 0;
      right: 0;
    }
    @media only screen and (max-width: 700px) {
      margin-bottom: 50px;
    }
  }
}

// OverlayModal
.backdrop {
  position: absolute;
  width: 100vw;
  height: 120%;
  background-color: rgba($color: #000000, $alpha: 0.8);

  top: 0;
  right: 0;

  overflow-y: hidden;

  @media only screen and (max-width: 700px) {
    height: clamp(200%, 1000px, 90vh);
  }
}

.modal-container {
  display: flex;

  justify-content: center;
  align-items: center;

  // width: 100vw;
  // height: 100vh;
}

.BigBlobs {
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 0 25px;

  border-radius: 25px;
  font-family: $inter;

  position: absolute;
  top: 20%;
  left: 20%;

  width: 50vw;

  svg {
    font-size: 3rem;
  }
  h4,
  h3 {
    margin: 0;
    padding-top: 15px;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }
  z-index: 999;

  @media only screen and (max-width: 900px) {
    width: 80vw;
    position: absolute;
    top: 20%;
    left: 3%;
    margin: 0;
  }
}

.modalButton {
  width: 100%;
  margin-top: 25px;
  padding: 15px 0;
  border: 3px solid white;

  font-family: $inter;
  font-size: 1.3rem;

  cursor: pointer;
}

// Keyframes
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
