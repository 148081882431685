@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+Toto:wght@400;500;600;700&display=swap");
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html body {
  margin: 0;
}

.App-Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 3rem 5rem;
  background-size: 200% 200%;
  -webkit-animation: gradient 10s ease infinite;
          animation: gradient 10s ease infinite;
  background-image: radial-gradient(at 44% 0%, rgba(255, 184, 122, 0.4) 0px, transparent 50%), radial-gradient(at 80% 0%, rgba(31, 221, 255, 0.1) 0px, transparent 50%), radial-gradient(at 80% 50%, rgba(255, 133, 173, 0.1) 0px, transparent 50%), radial-gradient(at 0% 100%, rgba(255, 181, 138, 0) 0px, transparent 50%), radial-gradient(at 80% 100%, rgba(107, 102, 255, 0) 0px, transparent 50%), radial-gradient(at 0% 0%, rgba(255, 133, 167, 0.25) 0px, transparent 50%);
}

@media only screen and (max-width: 700px) {
  .App-Container {
    padding: 3rem 1rem;
  }
}

.Header .Header-Logo {
  height: 100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 700px) {
  .Header {
    margin-bottom: 200px;
  }
}

.Main-Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: clamp(400px, 78vh, 90vh);
}

.Main {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 60%;
}

.Main p {
  font-family: "Inter", sans-serif;
}

.Main p a {
  text-decoration: none;
  color: black;
}

.Main h1 {
  font-family: "Inter", sans-serif;
}

@media only screen and (max-width: 700px) {
  .Main {
    width: 80%;
  }
}

.Blobs-Showcase {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 1000px) {
  .Blobs-Showcase {
    width: 100%;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .Blobs-Showcase {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.Blobs-Showcase .Blobs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 25px;
  margin: 0 25px;
  width: 220px;
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .Blobs-Showcase .Blobs {
    margin-top: 25px;
  }
}

.Blobs-Showcase .Blobs svg {
  font-size: 3rem;
}

.Blobs-Showcase .Blobs h4,
.Blobs-Showcase .Blobs h3 {
  margin: 0;
  padding-top: 15px;
}

.Blobs-Showcase #blob0 {
  position: absolute;
  top: 25%;
  right: 20%;
}

@media only screen and (max-width: 1000px) {
  .Blobs-Showcase #blob0 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0;
  }
}

.Blobs-Showcase #blob1 {
  position: absolute;
  top: 45%;
  right: 5%;
}

@media only screen and (max-width: 1000px) {
  .Blobs-Showcase #blob1 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0;
  }
}

.Blobs-Showcase #blob2 {
  position: absolute;
  top: 65%;
  right: 20%;
}

@media only screen and (max-width: 1000px) {
  .Blobs-Showcase #blob2 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 700px) {
  .Blobs-Showcase #blob2 {
    margin-bottom: 50px;
  }
}

.backdrop {
  position: absolute;
  width: 100vw;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
  overflow-y: hidden;
}

@media only screen and (max-width: 700px) {
  .backdrop {
    height: clamp(200%, 1000px, 90vh);
  }
}

.modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.BigBlobs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 25px;
  margin: 0 25px;
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 50vw;
  z-index: 999;
}

.BigBlobs svg {
  font-size: 3rem;
}

.BigBlobs h4,
.BigBlobs h3 {
  margin: 0;
  padding-top: 15px;
}

.BigBlobs h3 {
  font-size: 2.5rem;
}

.BigBlobs h4 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 900px) {
  .BigBlobs {
    width: 80vw;
    position: absolute;
    top: 20%;
    left: 3%;
    margin: 0;
  }
}

.modalButton {
  width: 100%;
  margin-top: 25px;
  padding: 15px 0;
  border: 3px solid white;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  cursor: pointer;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
